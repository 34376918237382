<template>
    <PageLayout>
        <v-form @submit.prevent="getEmployeePositions">
            <v-btn v-show="$vuetify.breakpoint.xsOnly" @click.stop="addEmployeePosition()"
                   color="indigo" dark fab fixed top right class="v-btn--add-form-top">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <TabsNav />
                <v-row class="pt-5">
                    <v-col cols="12" sm="8" class="title_menu mt-1">
                        {{  $t('employee_positions') }}
                        <v-icon color="primary" @click="filter_show===true ? filter_show=false : filter_show=true">mdi-filter-plus</v-icon>
                    </v-col>
                    <v-col v-if="!$vuetify.breakpoint.xsOnly" v-show="!filter_show" cols="12" sm="4"  class="text-right">
                        <v-btn :to="{name: 'employee_position.create'}" class="infinity_button" color="primary">
                            {{$t('create') }}
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row >
                    <v-col v-if="filter_show" class="py-0" cols="12">
                        <ValidationProvider ref="employee_position" rules=""
                                            v-slot="{ errors, valid }">
                            <v-select v-model="employee_position"
                                      :items="employee_positionItems" :error-messages="errors"
                                       :error="!valid"
                                       item-text="name" item-value="id"
                                      :label="$t('employee_position')"
                                      outlined
                                      solo
                                      flat
                                      dense
                                      :disabled="loading"
                                      :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                      color="primary"
                                      class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                      clearable></v-select>
                        </ValidationProvider>
                    </v-col>
                </v-row>
                <div v-if="filter_show" class="px-4 py-4">
                    <v-row>
                        <v-col cols="12" sm="12" md="12"
                               class="d-flex justify-center justify-sm-end align-self-center">
                            <v-btn type="submit" :disabled="invalid || loading" :block="$vuetify.breakpoint.xsOnly"
                                   color="primary" class="infinity_button">{{ $t('search') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </div>
            </ValidationObserver>
        </v-form>
        <v-card flat class="background_color_transparent">
            <v-card-text class="pt-7">
                <v-data-table item-key="id" ref="table" :headers="headers" :items="employee_positionItems"
                              :options.sync="options" disable-pagination
                              :sort-by.sync="sortBy" :sort-desc.sync="sortDir"
                              :loading="loading" :locale="lang" :loading-text="$t('loading_please_wait')"
                              hide-default-footer
                              :hide-default-header="(!$vuetify.breakpoint.xsOnly)"
                              class="sortable-table elevation-0"
                              :item-class="rowClass">
                    <template v-slot:header="{ props: { headers } }" v-if="(!$vuetify.breakpoint.xsOnly)">
                        <thead>
                        <tr class="rowHeadClass Table1Sort">
                            <th v-for="h in headers" :key="h.value" class="table_header">
                                <span>{{h.text}}</span>
                            </th>
                        </tr>
                        </thead>
                    </template>

                    <template v-slot:item.name="{ item }">
                        <div :class="item.deleted ? 'red--text' : ''">
                            <span  class="cursor-pointer font_weight_600" @click="editEmployeePosition(item)" >
                                {{  item.name }}
                                {{ item.deleted ? ' (' + $t('deleted') + ')' : '' }}
                            </span>
                            <v-icon v-if="!item.deleted" @click="deleteEmployeePosition(item)" small>mdi-delete-outline</v-icon>
                        </div>
                    </template>
                    <template v-slot:item.sort>
                        <v-btn icon class="handle">
                            <v-icon>mdi-drag-horizontal</v-icon>
                        </v-btn>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </PageLayout>
</template>
<script>

import {ValidationObserver, ValidationProvider} from 'vee-validate'
import Sortable from 'sortablejs'
import {mapGetters} from "vuex"
import TabsNav from "@/components/Leentech/TabsNav.vue";
import PageLayout from "@/components/Leentech/PageLayout.vue";

export default {
    name: 'EmployeePosition',
    components: {
        TabsNav,
        ValidationProvider,
        ValidationObserver,
        PageLayout
    },
    inject: ['forceRerender'],
    data() {
        return {
            heading: this.$t('employee_position_creation'),
            progress: 0,
            language: null,
            loading: false,
            sortBy: "sort",
            sortDir: false,
            options: {},
            employee_positionItems: [],
            errors: {},
            id: null,
            name: null,
            employee_position: null,
            headers: [
                {
                    text: "",
                    align: "left",
                    sortable: false,
                    value: "sort",
                },
                {
                    text: this.$t('name'),
                    align: "left",
                    sortable: false,
                    value: "name"
                },
            ],
            filter_show: false,
        }
    },
    computed: {
        ...mapGetters(['listLanguages', 'lang']),
        languages() {
            return this.listLanguages
        },
    },
    mounted() {
        this.language = this.languages[this.tab]
        this.checkCreate()
        let table = this.$refs.table.$el.querySelector("tbody")
        Sortable.create(table, {
            handle: ".handle",
            onEnd: this.dragReorder
        })

    },
    watch: {
        options: {
            handler() {
                this.getEmployeePositions()
            },
            deep: false
        }
    },
    methods: {
        rowClass(){
            return (!this.$vuetify.breakpoint.xsOnly) ? 'rowClass Table1Sort' : 'rowMobiClass';
        },
        setLanguage(val) {
            this.language = this.languages[val]
        },
        checkCreate() {
             this.heading = this.$t('employee_position_creation')

        },
        dragReorder({newIndex, oldIndex}) {
            const rowSelected = this.employee_positionItems.splice(oldIndex, 1)[0]
            this.employee_positionItems.splice(newIndex, 0, rowSelected)
            this.saveSortEmployeePosition()
        },
        async saveSortEmployeePosition() {
            var _this = this
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.employee_positionItems && this.employee_positionItems.length > 0) {
                for (let i in this.employee_positionItems) {
                    if (this.employee_positionItems[i].id !== 'undefined' && this.employee_positionItems[i].id > 0) {
                        formData.append(`sort[${i}]`, this.employee_positionItems[i].id)
                    }
                }
            }
            await this.$http
                .post(`admin/employee_position/sort`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('employee_position_sorting_updated'))
                    //this.forceRerender()
                })
                .catch(err => {
                    this.$toastr.error(this.$t('employee_position_sorting_not_updated'))
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },
        async getEmployeePositions() {
            this.loading = true
            const {
                sortBy,
                sortDesc,
            } = this.options
            let params = {}

            if (sortBy[0] !== undefined) {
                params.sortBy = sortBy[0]
            }
            if (sortDesc[0] !== undefined) {
                params.sortDir = sortDesc[0] ? 'desc' : 'asc'
            }
            if (this.employee_position) {
                params.name = this.employee_position
            }

            await this.$http
                .get("admin/employee_position", {
                    params: params,
                })
                .then(res => {
                    this.employee_positionItems = res.body.data
                })
                .catch(err => {
                    this.employee_positionItems = []
                })
                .finally(end => {
                    this.loading = false
                })
        },
        async deleteEmployeePosition(item) {
            if (confirm(this.$t('delete_employee_position'))) {
                this.loading = true
                await this.$http
                    .delete(`admin/employee_position/${item.id}`)
                    .then(res => {
                        this.getEmployeePositions()
                    })
                    .catch(err => {
                        this.EmployeePositionStatusItems = []
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        },
        addEmployeePosition() {
            this.$router.push({
                name: 'employee_position.create',
            })
        },
        editEmployeePosition(item) {
            this.$router.push({
                name: 'employee_position.edit',
                params: {
                    id: item.id
                }
            })
        }
    }
}
</script>
